<template>

  <div
    class="p-1"
    style="width: auto; height: 80vh; overflow-y: scroll;"
  >
    <!-- {{ userId}} -->
    <!-- {{ employeeInfo }} -->
    <!-- {{ employeeInfo.user_id }} -->
    <div
      class="mb-1 saj-title d-flex justify-content-start"
      style=""
    >
      {{ $t ("Employee Info") }}
    </div>
    <validation-observer ref="validateEmployee">
      <div
        class="row mb-2"
      >
        <div
          class="col-lg saj-text"
        >
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Employee Name") }} :
          </div>
          <b-form-input
            v-model="full_name"
            size="lg"
            class="saj-form-text"
            :placeholder="$t('Name')"
            rows="8"
            disabled
          />
        </div>
      </div>

      <b-form>
        <div
          class="row"
        >
          <div class="col-lg-4 saj-text">
            <b-form-group
              class="saj-text"
              :label="$t('First Name')"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('First Name')"
                rules="required"
              >
                <b-form-input
                  v-model="first_name"
                  :placeholder="$t('Enter First Name')"
                  class="saj-form-text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-lg-4 saj-text">
            <b-form-group
              class="saj-text"
              :label="$t('Middle Name')"
            >
              <b-form-input
                v-model="middle_name"
                :placeholder="$t('Enter Middle Name')"
                class="saj-form-text"
              />
            </b-form-group>
          </div>
          <div class="col-lg-4 saj-text">
            <b-form-group
              class="saj-text"
              :label="$t('Last Name')"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('Last Name')"
                rules="required"
              >
                <b-form-input
                  v-model="last_name"
                  :placeholder="$t('Enter Last Name')"
                  class="saj-form-text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>

        </div>
      </b-form>
      <!-- </validation-observer> -->
      <div
        class="row mb-1"
      >
        <div class="col-lg-4 saj-text">
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Ethnicity") }} :
          </div>
          <b-form-select
            v-model="race"
            :options="isEnglish ? raceoptions_bi : raceoptions"
            :placeholder="$t('Select Ethnicity')"
            size="lg"
            class="saj-form-text"
            rows="8"
          />
        </div>
        <div class="col-lg-4 saj-text">
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Religion") }} :
          </div>
          <b-form-select
            v-model="religion"
            :options="isEnglish ? religionoptions_bi : religionoptions"
            :placeholder="$t('Select Religion')"
            size="lg"
            class="saj-form-text"
            rows="8"
          />
        </div>
        <div class="col-lg-4 saj-text">
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Citizenship") }} :
          </div>
          <b-form-select
            v-model="citizenship"
            :options="citizenshipoptions"
            :placeholder="$t('Select Citizenship')"
            size="lg"
            class="saj-form-text"
            rows="8"
          />
        </div>
      </div>
      <div
        class="row mb-1"
      >
        <div class="col-lg-6 saj-text">
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Identification Number") }} :
          </div>
          <validation-provider
            #default="{ errors }"
            :name="$t('Identification Number')"
            :rules="{required,regex: /^\d{6}\d{2}\d{4}$/}"
          >
            <b-form-input
              v-model="ic"
              :placeholder="$t('Eg: xxxxxxxxxxxx')"
              type="number"
              size="lg"
              class="saj-form-text"
              rows="8"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </div>
        <div class="col-lg-6 saj-text">
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Date of Birth") }} :
          </div>
          <validation-provider
            #default="{ errors }"
            :name="$t('Date of Birth')"
            rules="required"
          >
            <input
              v-model="dob"
              type="date"
              style="background: #fff; height: 3.25rem;"
              class="form-control"
            >
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </div>
      </div>
      <div
        class="row mb-1"
      >
        <div class="col-lg-6 saj-text">
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Gender") }} :
          </div>
          <b-form-select
            v-model="gender"
            :options="isEnglish ? genderoptions_bi : genderoptions"
            :placeholder="$t('Select Gender')"
            size="lg"
            class="saj-form-text"
            rows="8"
          />
        </div>
        <div class="col-lg-6 saj-text">
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Maritial Status") }} :
          </div>
          <b-form-select
            v-model="marital_status"
            :options="isEnglish ? marital_statusoptions_bi : marital_statusoptions"

            :placeholder="$t('Select Status')"
            size="lg"
            class="saj-form-text"
            rows="8"
          />
        </div>
        <div class="col-lg-6 saj-text">
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Address 1") }} :
          </div>
          <b-form-input
            v-model="address_line1"
            size="lg"
            class="saj-form-text"
            rows="8"
          />
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Address 2") }} :
          </div>
          <b-form-input
            v-model="address_line2"
            size="lg"
            class="saj-form-text"
            rows="8"
          />
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Address 3") }} :
          </div>
          <b-form-input
            v-model="address_line3"
            size="lg"
            class="saj-form-text"
            rows="8"
          />
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Postal Code") }} :
          </div>
          <b-form-input
            v-model="postal_code"
            size="lg"
            class="saj-form-text"
            rows="8"
          />
          <div class="m-0 mt-1 mb-1">
            {{ $t ("City") }} :
          </div>
          <b-form-input
            v-model="city"
            size="lg"
            class="saj-form-text"
            rows="8"
          />
        </div>
        <div class="col-lg-6 saj-text">
          <div class="m-0 mt-1 mb-1">
            {{ $t ("State") }} :
          </div>
          <b-form-input
            v-model="state"
            size="lg"
            class="saj-form-text"
            rows="8"
          />
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Country") }} :
          </div>
          <b-form-input
            v-model="country"
            size="lg"
            class="saj-form-text"
            rows="8"
          />
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Address") }} :
          </div>
          <b-form-textarea
            v-model="address"
            disabled
            size="lg"
            class="saj-form-text"
            rows="8"
          />
        </div>
      </div>
      <!-- </validation-observer> -->
      <!-- sini -->
      <!-- new template work info -->
      <div
        class="row pt-1 pb-2"
        style="
      background: none;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
              0px 0px 41px 9px rgba(0, 0, 0, 0.11);
              border-radius: 10px;"
      >
        <div class="col">
          <div
            class="row pl-1"
          >
            <div
              class="saj-title mb-1"
            >
              {{ $t("Work Info") }}
            </div>
          </div>
          <div
            class="row mb-1"
          >
            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Business Unit") }} :
              </div>
              <validation-provider
                #default="{ errors }"
                :name="$t('Business Unit')"
                rules="required"
              >
                <v-select
                  v-model="business_unit"
                  :placeholder="$t('Select Business Unit')"
                  class="saj-form-text select-size-lg"
                  rows="8"
                  :options="businessUnitList"
                  :reduce="text => text.value"
                  label="text"
                  @input="getDepartment(business_unit), getWorkLocation(business_unit)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Position") }} :
              </div>
              <validation-provider
                #default="{ errors }"
                :name="$t('Position')"
                rules="required"
              >
                <v-select
                  v-model="position"
                  :placeholder="$t('Select Position')"
                  class="saj-form-text select-size-lg"
                  rows="8"
                  :options="positionList"
                  :value="positionList"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Role(s)") }} :
              </div>
              <!-- <validation-provider
                  #default="{ errors }"
                  :name="$t('Role(s)')"
                  rules="required"
                > -->
              <v-select
                v-model="userRoles"
                class="saj-title select-size-lg"
                style="background: white"
                label="text"
                :placeholder="$t('Select Role(s)')"
                multiple
                :options="allRoles"
                :selectable="(option) => !allRoles.includes(option.value)"
                :value="allRoles"
                @input="updateRole(userRoles)"
              />
              <!-- <small class="text-danger">{{ errors[0] }}</small> -->
              <!-- </validation-provider> -->
            </div>
            <div class="col-lg-6 saj-text" />
            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Supervisor Id") }} :
              </div>
              <!-- {{ empData }} -->
              <v-select
                v-model="sv_employee_number"
                :placeholder="$t('Supervisor Id')"
                class="saj-form-text select-size-lg"
                rows="8"
                :options="svNameList"
                label="empNo"
                @input="showValue(sv_employee_number)"
              />
            </div>
            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Supervisor Name") }} :
              </div>

              <b-form-input
                v-model="supervisorName"
                size="lg"
                disabled
                class="saj-text m-0"
                :placeholder="$t('Supervisor Name')"
              />
            </div>
          </div>
          <div
            class="row mb-1"
          >
            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Department") }}:
              </div>
              <validation-provider
                #default="{ errors }"
                :name="$t('Department')"
                rules="required"
              >
                <v-select
                  v-model="department_id"
                  :placeholder="$t('Select Department')"
                  class="saj-form-text select-size-lg"
                  rows="8"
                  :options="departmentList"
                  :reduce="text => text.value"
                  label="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Work Location") }} :
              </div>
              <validation-provider
                #default="{ errors }"
                :name="$t('Work Location')"
                rules="required"
              >
                <v-select
                  v-model="branch_id"
                  :placeholder="$t('Select Work Location')"
                  class="saj-form-text select-size-lg"
                  rows="8"
                  :options="branchList"
                  :reduce="text => text.value"
                  label="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
          <div
            class="row mb-1"
          >
            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Grade") }} :
              </div>
              <validation-provider
                #default="{ errors }"
                :name="$t('Grade')"
                rules="required"
              >
                <v-select
                  v-model="grade"
                  :placeholder="$t('Select Grade')"
                  class="saj-form-text select-size-lg"
                  rows="8"
                  :options="gradeList"
                  label="text"
                  @input="getGradeSet(), getAllRoles()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Grade set") }} :
              </div>
              <b-form-input
                v-model="grade_set"
                :placeholder="$t('Select Grade Set')"
                :disabled="grade === null || grade === '' "
                size="lg"
                class="saj-form-text"
                :readonly="true"
                rows="8"
              />
            </div>
          </div>
          <div
            class="row mb-1"
          >
            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Employee Number") }} :
              </div>
              <validation-provider
                #default="{ errors }"
                :name="$t('Employee Number')"
                rules="required"
              >
                <b-form-input
                  v-model="employee_number"
                  :placeholder="$t('Enter Employee Number')"
                  size="lg"
                  class="saj-form-text"
                  rows="8"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Date of Joined") }} :
              </div>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Date of Joined')"
                  rules="required"
                >
                  <!-- <flat-pickr
                      v-model="empData.hire_date"
                      class="saj-form-text form-control dateJoined"
                      style="background: #fff; height: 3.25rem;"
                      :config="{ dateFormat: 'Y-m-d' , altInput: true, altFormat:'d-m-Y'}"
                      size="lg"
                    /> -->
                  <input
                    v-model="hire_date"
                    type="date"
                    style="background: #fff; height: 3.25rem;"
                    class="form-control"
                  >
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
          <div
            class="row mb-1"
          >
            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Email") }} :
              </div>
              <validation-provider
                #default="{ errors }"
                :name="$t('Email')"
                rules="required|email"
              >
                <b-form-input
                  v-model="email"
                  :placeholder="$t('Enter Email')"
                  size="lg"
                  class="saj-form-text"
                  rows="8"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Profile Picture") }} :
              </div>
              <b-form-file
                v-model="profile_picture"
                type="file"
                class="saj-text"
                size="lg"
                :browse-text="$t('Choose File')"
                :placeholder="$t('No file choosen')"
                @change="onFileChange"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-center"
        style=""
      >
        <button
          class="btn m-1 saj-button"
          aria-label="submit modal"
          style="

                    color: white;
                    background: #0b103c !important;
                    border-color: #0b103c !important;
                  "
          @click="activateEmployee()"
        >
          {{ $t('Save') }}
        </button>
        <button
          class="btn btn-close m-1 saj-button"
          aria-label="Close modal"
          style="

                    color: white;
                    background: #ff0000;
                  "
          @click="close()"
        >
          {{ $t('Back') }}
        </button>
      </div>
      <!-- </validation-observer> -->
    </validation-observer></div>

</template>
<script>
import {
  BForm,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormFile,
  BFormTextarea,
} from "bootstrap-vue"
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
// import flatPickr from "vue-flatpickr-component"
import vSelect from 'vue-select'
import SAJToast from '@/component/saj-toastification.vue'

export default {
  components: {
    // BButton,
    // BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    // BInputGroup,
    // BFormGroupAppend,
    // BCard,
    // BFormTimepicker,
    // BCardCode,
    // BRow,
    // BCol,
    // BCalendar,
    // BCardText,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    // flatPickr,
    BFormFile,
    vSelect,
  },
  props: {
    empInfo: {
      type: Object,
      default: () => {},
    },
    subsID: {
      type: null,
      default: null,
    },
    userId: {
      type: null,
      default: null,
    },
    employeeInfo: {
      type: null,
      default: null,
    },

  },
  data() {
    return {
      full_name: null,
      first_name: null,
      middle_name: null,
      race: null,
      last_name: null,
      religion: null,
      citizenship: null,
      ic: null,
      dob: null,
      gender: null,
      marital_status: null,
      address_line1: null,
      address_line2: null,
      address_line3: null,
      postal_code: null,
      city: null,
      state: null,
      country: null,
      address: null,
      business_unit: null,
      position: null,
      sv_employee_number: null,
      supervisorName: null,
      department_id: null,
      branch_id: null,
      grade: null,
      grade_set: null,
      employee_number: null,
      hire_date: null,
      profile_picture: [],
      email: null,

      supervisor: [],
      // name: null,
      // details: null,
      required,
      fullName: '',
      userRoles: [],
      subsidiaryID: null,
      businessUnitList: [],
      allbusinessUnit: [],
      departmentList: [],
      alldepartment: [],
      allBranch: [],
      allRoles: [],
      branchList: [],
      positionList: [],
      allPosition: [],
      gradeList: [],
      allGrade: [],
      allSubsidiary: [],
      subsidiaryStaffList: [],
      genderoptions_bi: [
        { value: null, text: "Choose Gender", disabled: true },
        { value: "Male", text: "Male" },
        { value: "Female", text: "Female" },
      ],
      genderoptions: [
        { value: null, text: "Pilih Jantina", disabled: true },
        { value: "Male", text: "Lelaki" },
        { value: "Female", text: "Perempuan" },
      ],
      marital_statusoptions_bi: [
        // { value: null, text: "Choose Marital status", disabled: true },
        { value: null, text: "Choose Marital status", disabled: true },
        { value: "Single", text: "Single" },
        { value: "Married", text: "Married" },
        { value: "Divorced", text: "Divorced" },
        { value: "Widowed", text: "Widowed" },
      ],
      marital_statusoptions: [
        { value: null, text: "Pilih Status Perkahwinan", disabled: true },
        { value: "Single", text: "Bujang" },
        { value: "Married", text: "Berkahwin" },
        { value: "Divorced", text: "Bercerai" },
        { value: "Widowed", text: "Balu/Janda/Duda" },
      ],
      raceoptions_bi: [
        { value: null, text: "Choose Ethnicity", disabled: true },
        { value: "Malay", text: "Malay" },
        { value: "Chinese", text: "Chinese" },
        { value: "Indian", text: "Indian" },
        { value: "Others", text: "Others" },
      ],
      raceoptions: [
        { value: null, text: "Pilih Kaum", disabled: true },
        { value: "Malay", text: "Melayu" },
        { value: "Chinese", text: "Cina" },
        { value: "Indian", text: "India" },
        { value: "Others", text: "Lain-lain" },
      ],
      religionoptions_bi: [
        { value: null, text: "Choose Religion status", disabled: true },
        { value: "Islam", text: "Islam" },
        { value: "Hinduism", text: "Hinduism" },
        { value: "Buddhism", text: "Buddhism" },
        { value: "Christianity", text: "Christianity" },
        { value: "Others", text: "Others" },
      ],
      religionoptions: [
        { value: null, text: "Pilih Agama", disabled: true },
        { value: "Islam", text: "Islam" },
        { value: "Hinduism", text: "Hinduism" },
        { value: "Buddhism", text: "Buddhism" },
        { value: "Christianity", text: "Kristianiti" },
        { value: "Others", text: "Lain-lain" },
      ],
      citizenshipoptions: [
        { value: null, text: `${this.$i18n.t("Choose citizenship")}`, disabled: true },
        { value: "Malaysia", text: "Malaysia" },
        { value: "Others", text: "Others" },
      ],
      svNameList: [],
      supervisorListName: [],
    }
  },
  computed: {
    isEnglish() {
      return this.$i18n.locale === 'en'
    },
  },
  beforeMount() {
    this.id = this.$route.params.id
  },
  mounted() {
    this.subsidiaryID = this.subsID
    this.getAllSubsidiary()
    this.getSubsidiary()
    this.getDepartment(this.employeeInfo.business_unit)
    this.getWorkLocation(this.employeeInfo.business_unit)
    this.getPosition()
    this.getGrade()
    this.getAllRoles()
    this.getSvName()
    this.getUserRole()
    this.getEmployeeInfo()
  },
  methods: {
    getEmployeeInfo(){
      this.$axios.get(`${this.$baseUrl}/employees/getEmployeeByUserId?user_id=${this.userId}`).then(response => {
        this.allEmpInfo = response.data.data
        this.full_name = this.allEmpInfo.full_name
        this.first_name = this.allEmpInfo.first_name
        this.middle_name = this.allEmpInfo.middle_name
        this.last_name = this.allEmpInfo.last_name
        this.race = this.allEmpInfo.race
        this.religion = this.allEmpInfo.religion
        this.citizenship = this.allEmpInfo.citizenship
        this.ic = this.allEmpInfo.ic
        this.dob = this.allEmpInfo.dob
        this.gender = this.allEmpInfo.gender
        this.marital_status = this.allEmpInfo.marital_status
        this.address_line1 = this.allEmpInfo.address_line1
        this.address_line2 = this.allEmpInfo.address_line2
        this.address_line3 = this.allEmpInfo.address_line3
        this.postal_code = this.allEmpInfo.postal_code
        this.city = this.allEmpInfo.city
        this.state = this.allEmpInfo.state
        this.country = this.allEmpInfo.country
        this.address = this.allEmpInfo.address
        this.business_unit = this.allEmpInfo.business_unit
        this.position = this.allEmpInfo.position
        this.sv_employee_number = this.allEmpInfo.sv_employee_number
        this.department_id = this.allEmpInfo.department_id
        this.branch_id = this.allEmpInfo.branch_id
        this.grade = this.allEmpInfo.grade
        this.employee_number = this.allEmpInfo.employee_number
        this.hire_date = this.allEmpInfo.hire_date
        this.email = this.allEmpInfo.email

        // console.log('info', this.allEmpInfo)
        this.showValue(this.sv_employee_number !== null ? this.sv_employee_number : 'Please choose Supervisor')
        this.getGradeSet()
      })
    },
    onFileChange(e) {
      // console.log('on file change', e.target.files[0].size)
      if (e.target.files.length !== 0){
        const fileSize = e.target.files[0].size / 1000

        if (fileSize > 5120){
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('File must less than 5 MB')}.`,
                icon: "XIcon",
                variant: "success",
                titleColor: "#000",
                iconBg: '#e80202',
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
        }
      }
    },
    close() {
      this.$emit('close')
    },
    showValue(a) {
      // console.log("Show employee number:", a)
      this.$axios.get(`${this.$baseUrl}/employees/getAllEmployeesNameWithoutPerformance?employee_number=${a.empNo !== undefined ? a.empNo : a}`).then(response => {
        const SVname = response.data.data
        this.supervisorName = SVname[0].full_name !== undefined ? SVname[0].full_name : 'Please choose Supervisor'
        // console.log(this.supervisorName)
        // console.log("Full Name:", SVname)
      }).catch(() => {
        // console.log("sini", error)
        this.supervisorName = 'Please choose Supervisor'
      })
    },
    getSvName(){
      // console.log('sv name')
      this.$axios.get(`${this.$baseUrl}/employees/getAllEmployeesNameWithoutPerformance?subsidiary_id=${this.employeeInfo.business_unit}&status=active`).then(response => {
        // console.log(response)
        this.supervisorListName = response.data.data
        // console.log(response)
        this.supervisorListName.forEach(sv => {
          this.svNameList.push({
            // text: sv.full_name !== null ? sv.full_name : '',
            // value: sv.sv_employee_number !== null ? sv.sv_employee_number : '',
            empNo: sv.employee_number,
          })
        })
      })
    },
    getAllRoles(){
      this.allRoles = []
      const params = new URLSearchParams()

      params.append('toAssign', 1)
      this.$axios.get(`${this.$baseUrl}/roles/getAllRoles`, { params }).then(res => {
        // console.log('all user', res)
        const roles = res.data.data

        roles.forEach(k => {
          this.allRoles.push({
            text: k.name,
            value: k.id,
          })
        })
      })
    },
    getUserRole(){
      this.$axios.get(`${this.$baseUrl}/roles/getUserRole?user_id=${this.employeeInfo.user_id}&assign=1`).then(res => {
        const roles = res.data.data.role
        roles.forEach(k => {
          this.userRoles.push({
            text: k.role_name,
            value: k.role_id,
          })
        })
      })
    },
    updateRole(){
      // console.log('selected role', selectedRole)
    },
    getAllSubsidiary(){
      this.$axios.get(`${this.$baseUrl}/subsidiary/getAll`).then(response => {
        // console.log(response)
        this.allSubsidiary = response.data.data.subsidiaries
        this.businessUnitList = this.allSubsidiary.map(x => ({
          text: x.subsidiary_name,
          value: x.id,
        }))
      })
    },
    getSubsidiary(){
      this.$axios.get(`${this.$baseUrl}/subsidiary/getById?id=${this.employeeInfo.business_unit}`).then(response => {
        this.allbusinessUnit = response.data.data
        // this.allbusinessUnit.forEach(b => {
        //   this.businessUnitList.push({
        //     text: b.subsidiary_name,
        //     value: b.id,

        //   })
        // })
      })
    },
    getDepartment(dept){
      this.$axios.get(`${this.$baseUrl}/departments/get_departments_by_subsidiary?subsidiary_id=${dept}`).then(response => {
        const result = response.data
        if (result.success){
          this.alldepartment = response.data.data

          this.departmentList = this.alldepartment.map(x => ({
            text: x.name,
            value: x.id,
          }))
        }
      })
    },
    getWorkLocation(work){
      this.$axios.get(`${this.$baseUrl}/branch/get_branch_by_company?company_id=${work}`).then(response => {
        this.allBranch = response.data.data

        this.branchList = this.allBranch.map(x => ({
          text: x.name,
          value: x.id,
        }))
      })
    },
    getPosition(){
      this.$axios.get(`${this.$baseUrl}/position/get_position_all`).then(response => {
        this.allPosition = response.data.data

        this.allPosition.forEach(d => {
          this.positionList.push(d.name)
        })
      })
    },
    getGrade(){
      this.$axios.get(`${this.$baseUrl}/grades/`).then(response => {
        // console.log(response)
        this.allGrade = response.data.data
        this.gradeList = this.allGrade.map(d => (d.grade))
      })
    },
    getGradeSet(){
      if (this.grade !== null){
        this.$axios.get(`${this.$baseUrl}/grades/get_grade_set?grade=${this.grade}`).then(response => {
          // console.log(response)
          this.grade_set = response.data.data.grade_set
        })
      } else {
        this.grade_set = null
      }
    },
    addUserRole(a){
      const data = new FormData()
      data.append('user_id', a)
      this.userRoles.forEach(item => {
        data.append('role_id[]', item.value)
      })

      this.$axios.post(`${this.$baseUrl}/roles/updateUserRole`, data)
        .then(() => {
        })
    },
    updateUserRole(){
      const data = new FormData()
      data.append('user_id', this.userId)
      this.userRoles.forEach(item => {
        data.append('role_id[]', item.value)
      })

      this.$axios.post(`${this.$baseUrl}/roles/updateUserRole`, data)
        .then(() => {
          this.$emit('close')
          this.$emit('update-employee')
        }).catch(err => {
          this.$emit('close')
          this.$emit('update-employee')
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('Roles failed to update')}.`,
                text: err.response.data.message,
                icon: 'XIcon',
                iconBg: '#e80202',
                titleColor: '#000',
              },
            },
            {
              position: "top-right",
              type: 'error',
            },
          )
        })
    },
    activateEmployee(){
      this.$refs.validateEmployee.validate().then(success => {
        if (success){
          const data = new FormData()
          this.fullName = `${this.first_name} ${this.middle_name === null ? '' : this.middle_name} ${this.last_name}`

          data.append('status', 'active')
          data.append('user_id', this.userId)
          data.append('business_unit', this.business_unit)
          data.append('employee_number', this.employee_number)
          data.append('full_name', this.fullName)
          data.append('first_name', this.first_name)
          data.append('middle_name', this.middle_name === null || this.middle_name === "" ? "" : this.middle_name)
          data.append('last_name', this.last_name)
          data.append('email', this.email)
          data.append('ic', this.ic)
          data.append('department_id', this.department_id)
          data.append('branch_id', this.branch_id)
          data.append('grade', this.grade)
          data.append('position', this.position)
          data.append('race', this.race)
          data.append('religion', this.religion)
          data.append('citizenship', this.citizenship)
          data.append('dob', this.dob)
          data.append('gender', this.gender)
          data.append('marital_status', this.marital_status)
          data.append('hire_date', this.hire_date)
          data.append('file', this.profile_picture)
          data.append('address_line1', this.address_line1 === undefined || this.address_line1 === null ? "" : this.address_line1)
          data.append('address_line2', this.address_line2 === undefined || this.address_line2 === null ? "" : this.address_line2)
          data.append('address_line3', this.address_line3 === undefined || this.address_line3 === null ? "" : this.address_line3)
          data.append('postal_code', this.postal_code === undefined || this.postal_code === null ? "" : this.postal_code)
          data.append('city', this.city === undefined || this.city === null ? "" : this.city)
          data.append('state', this.state === undefined || this.state === null ? "" : this.state)
          data.append('country', this.country === undefined || this.country === null ? "" : this.country)
          if (this.sv_employee_number !== null) {
            data.append('supervisor_number', this.sv_employee_number.empNo !== undefined ? this.sv_employee_number.empNo : this.sv_employee_number)
          }

          this.$axios.post(`${this.$baseUrl}/employees/setActiveInactiveEmployee`, data)
            .then(() => {
              this.updateUserRole()
              this.$emit('refresh-table')
              setTimeout(() => {
                this.$toast(
                  {
                    component: SAJToast,
                    props: {
                      title: `${this.$t('Successfully activated employee')}.`,
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                      titleColor: '#000',
                    },
                  },
                  {
                    position: "top-right",
                    type: 'info',
                  },
                )
              }, 1000)
            })
        }
      })
    },
  },
}
</script>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  </style>

  <style>
    .dateJoined .flatpickr-input, .flatpickr-input ~ .form-control, .flatpickr-human-friendly {
    height: 53px !important
  }
  </style>

  <!-- .flatpickr-input, .flatpickr-input ~ .form-control, .flatpickr-human-friendly -->
