<template>
  <div class="p-1" style="width: auto; height: 60vh; overflow-y: scroll">
    <div
      class="mb-3 d-flex justify-content-center"
      style="font-size: 30px; font-weight: 600"
    >
      {{ $t("Transfer/Promotion Details") }}
    </div>

    <!-- Form starts here -->
    <form @submit.prevent="handleSubmit">
      <div class="row mb-2">
        <!-- Employee Number -->
        <div class="col-lg-6">
          <div class="form-group">
            <label>{{ $t("Employee Number") }}</label>
            <input
              type="text"
              class="form-control"
              v-model="employee_number"
              disabled
            />
          </div>
        </div>

        <!-- Employee Name -->
        <div class="col-lg-6">
          <div class="form-group">
            <label>{{ $t("Employee Name") }}</label>
            <input type="text" class="form-control" v-model="name" disabled />
          </div>
        </div>
      </div>

      <!-- Current Work Information Row -->
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <label>{{ $t("Subsidiary") }}</label>
            <select
            class="form-control"
            v-model="subsidiaryId"
            @change="updateSubsidiary"
          >
            <option v-for="sub in subList" :key="sub.value" :value="sub.value">
              {{ sub.text }}
            </option>
          </select>
        
          </div>
          <div class="form-group">
            <label>{{ $t("Department") }}</label>
            <select
            class="form-control"
            v-model="departmentId"
            
          >
            <option v-for="dept in departmentList" :key="dept.value" :value="dept.value">
              {{ dept.text }}
            </option>
          </select>
        
          </div>
          <div class="form-group">
            <label>{{ $t("Branch") }}</label>
            <select
            class="form-control"
            v-model="branchId"
            
          >
            <option v-for="bran in branchList" :key="bran.value" :value="bran.value">
              {{ bran.text }}
            </option>
          </select>
          </div>
          <div class="form-group">
            <label>{{ $t("Grade") }}</label>
            <select
            class="form-control"
            v-model="gradeId"
            
          >
            <option v-for="gra in gradeList" :key="gra.value" :value="gra.value">
              {{ gra.text }}
            </option>
          </select>
          </div>
          <div class="form-group">
            <label>{{ $t("Type") }}</label>
            <select 
              class="form-control" 
              v-model="type"
            >
              <option 
                v-for="transferType in transferTypeList" 
                :key="transferType" 
                :value="transferType"
              >
                {{ transferType }}
              </option>
            </select>
          </div>
          
          <div class="form-group" style="margin-top:30px">
            <label for="update" style="margin-right: 5px; font-size: 1.1rem; font-weight:400; height:2.714rem">{{ $t("Update :") }}</label>
            <input
              type="checkbox"
              style="margin-left: 10px; font-weight:400; width:16px; height:16px; color: red;"
              class="form-check-input"
              id="update"
              v-model="update"
            />

          </div>
          
        </div>

        <div class="col-lg-6">
          <div class="form-group">
            <label>{{ $t("Supervisor") }}</label>
            <select
            class="form-control"
            v-model="svId"
            
          >
            <option v-for="sup in supervisorList" :key="sup.value" :value="sup.value">
              {{ sup.text }}
            </option>
          </select>
          </div>
          <div class="form-group">
            <label>{{ $t("Position") }}</label>
            <select
            class="form-control"
            v-model="positionId"
            
          >
            <option v-for="pos in positionList" :key="pos.value" :value="pos.value">
              {{ pos.text }}
            </option>
          </select>
          </div>
          <div class="form-group">
            <label>{{ $t("Date Hired") }}</label>
            <input type="date" class="form-control" v-model="date_hired" />
          </div>
          <div class="form-group">
            <label>{{ $t("Reason for Transfer") }}</label>
            <input type="text" class="form-control" v-model="reason" />
          </div>
          <div class="form-group">
            <label>{{ $t("Achievement") }}</label>
            <input type="text" class="form-control" v-model="achievement" />
          </div>
        </div>
      </div>
      <div class="form-group text-right">
        <button type="submit" class="btn btn-primary">
          {{ $t("Submit") }}
        </button>
      </div>
    </form>
    <!-- Form ends here -->
  </div>
</template>

<script>
import // BForm,
"bootstrap-vue";

export default {
  components: {},
  props: {
    transfer: {
      type: Number,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      employee_number: null,
      name: null,
      subsidiary: null,
      department: null,
      branch: null,
      grade: null,
      gradeset: null,
      position: null,
      prevsubsidiary: null,
      prevdepartment: null,
      prevbranch: null,
      prevgrade: null,
      prevgradeset: null,
      prevposition: null,
      subList: [],
      departmentList: [], 
      branchList: [],
      typeList: [],
      supervisorList: [],
      positionList: [],
      gradeList: [],
      transferTypeList: ["Transfer", "Promotion", "Promotion & Transfer"],
      ///
      date_hired: null,
      type: "",
      reason: "",
      update: false,
      achievement: "",
      userId: null,
      subsidiaryId: null,
      departmentId: null,
      branchId: null,
      svId: null,
      gradeId: null,
      positionId: null,
    };
  },
  computed: {
    isEnglish() {
      return this.$i18n.locale === "en";
    },
  },
  mounted() {
    this.getDetails();
    this.getSubsidiaryList();
    this.getPosition();
    this.getGrade();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getSubsidiaryList(){
      this.$axios.get(`${this.$baseUrl}/subsidiary/getAll`).then(response => {
        const list = response.data.data.subsidiaries
        console.log(list, "subsidiary");
        this.subList = list.map(x => ({
          text: x.subsidiary_name,
          value: x.id,
        }))
      })
    },
    getDepartment(){
      this.$axios.get(`${this.$baseUrl}/departments/get_departments_by_subsidiary?subsidiary_id=${this.subsidiaryId}`).then(response => {
        const result = response.data
        console.log(result, "department");
        if (result.success){
          this.alldepartment = response.data.data

          this.alldepartment.forEach(d => {
            this.departmentList.push({
              text: d.name,
              value: d.id,
            })
          })
        }
      })
    },
    getBranch() {
      this.$axios.get(`${this.$baseUrl}/branch/get_branch_by_company?company_id=${this.subsidiaryId}`).then(response => {
        if (response.data.data.length !== 0){
          console.log(response.data.data, "BRANCH");
          const branches = response.data.data;
          this.branchList = branches.map(bra => ({
            text: bra.name,
            value: bra.id,
          }))
        }
      }).catch(() => {
        this.branchList = []
        this.show = false
      })
    },
    getGrade(){
      this.$axios.get(`${this.$baseUrl}/grades/`).then(response => {
       if (response.data.data.length !== 0) {
        console.log(response.data.data, "GRADE");
        const grades = response.data.data;
        this.gradeList = grades.map(gra =>({
          text: gra.grade,
          value: gra.id,
        }))
       }
      })
    },
    getSvName(){
      this.$axios.get(`${this.$baseUrl}/employees/getAllEmployeesNameWithoutPerformance?subsidiary_id=${this.subsidiaryId}&status=active`).then(response => {
        if (response.data.data.length !== 0){
          const supervisorListName = response.data.data
          console.log(supervisorListName, "SUPERVISOR");
          this.supervisorList = supervisorListName.map(sup => ({
            text: sup.full_name,
            value: sup.employee_id,
          }))
        }
      }).catch(() => {
        this.supervisorList = []

      })
    },
    getPosition(){
      this.$axios.get(`${this.$baseUrl}/position/get_position_all`).then(response => {
        if (response.data.data.length !== 0){
        const allPosition = response.data.data
        console.log(allPosition, "POSITION");
        this.positionList = allPosition.map(pos => ({
          text: pos.name,
          value: pos.id
        }))
      }
    }).catch(() => {
        this.positionList = []

      })
    },
    getDetails() {
      const data = new FormData();
      data.append("transfer_id", this.transfer);
      this.$axios
        .post(`${this.$baseUrl}/transfer_promotion/get_history`, data)
        .then((response) => {
          if (response.data.data.length !== 0) {
            const Data = response.data.data[0];
            console.log("Fetched Data:", Data);
            this.employee_number = Data.employee_number;
            this.name = Data.employee_name;
            ///
            this.userId = Data.transfer_id;
            this.subsidiaryId = Data.subsidiary_id;
            this.departmentId = Data.department_id;
            this.branchId = Data.branch_id;
            this.svId = Data.sv_id;
            this.gradeId = Data.grade_id;
            this.positionId = Data.position_id;
            this.type = Data.type;
            this.date_hired = Data.date ? Data.date.split(" ")[0] : null;

            this.prevsubsidiary = Data.previous_subsidiary_name;
            this.prevdepartment = Data.prev_department_name;
            this.prevbranch = Data.prev_branch_name;
            this.prevgrade = Data.prev_grade;
            this.prevgradeset = Data.prev_gradeset;
            this.prevposition = Data.prev_position;

            this.subsidiary = Data.subsidiary_name;
            this.department = Data.department_name;
            this.branch = Data.branch_name;
            this.grade = Data.grade;
            this.gradeset = Data.gradeset;
            this.position = Data.position;
          }
          this.getDepartment();
          this.getBranch();
          this.getSvName();
        })
        .catch(() => {
          // Add error handling here
          this.show = false;
        });
    },
    updateSubsidiary() {
      // Find the selected subsidiary in the list
      const selectedSubsidiary = this.subList.find(
        (sub) => sub.value === this.subsidiaryId
      );
      // Update both the subsidiary ID and name
      if (selectedSubsidiary) {
        this.subsidiary = selectedSubsidiary.text;
        this.getDepartment();
        this.getBranch();
        this.getSvName();
      } else {
        this.subsidiary = null; 
      }
    },

   updateDepartmentId(){
    const selectedDepartment = this.departmentList.find(
      (dep) => dep.value === this.departmentId
    );
    if (selectedDepartment) {
      this.department = selectedDepartment.text;
    } else {
        this.department = null;
      }
   },

    handleSubmit() {
      // Create FormData and append fields individually
      const data = new FormData();
      data.append("id", this.userId);
      data.append("subsidiary_id", this.subsidiaryId);
      data.append("department_id", this.departmentId);
      data.append("branch_id", this.branchId);
      data.append("sv_id", this.svId);
      data.append("grade_id", this.gradeId);
      data.append("date_hired", this.date_hired);
      data.append("reason", this.reason);
      data.append("position_id", this.positionId);
      data.append("achievement", this.achievement);
      data.append("type", this.type);
      data.append("update", this.update);
      this.$axios
        .post(`${this.$baseUrl}/transfer_promotion/update`, data)
        .then((response) => {
          // Handle response
          console.log("Data updated successfully", response);
        })
        .catch((error) => {
          // Handle error
          console.error("Error updating data", error);
        });
        this.close();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style>
.dateJoined .flatpickr-input,
.flatpickr-input ~ .form-control,
.flatpickr-human-friendly {
  height: 53px !important;
}
</style>
